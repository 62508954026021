import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import { showCookieInfoPopup } from '@cms/components/OneTrustCookieManager'
import type { PageFooterProps } from '@components/Layout/Page/Page.types'
import { MiniFooter } from '@knauf-group/ct-designs/components/core/Footer/MiniFooter'
import { WebFooter as Footer } from '@knauf-group/ct-shared-nextjs/components/WebFooter'

export const PageFooter: React.FC<PageFooterProps> = ({
  footerEntries,
  isMiniFooter = false,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common' })

  const router = useRouter()
  const analyticsPageName = getPageSlugFromRouterPath(router)

  const cookieSetting = {
    onClick: showCookieInfoPopup,
    cookieSettingLabel: t('cookies.cookiesSettings'),
  }

  return isMiniFooter ? (
    <MiniFooter
      cookieSetting={cookieSetting}
      copyright={footerEntries.copyright}
      legalLinks={footerEntries.legalLinks}
      nextLinkComponent={NextLink}
    />
  ) : (
    <Footer
      {...footerEntries}
      cookieSetting={cookieSetting}
      nextLinkComponent={NextLink}
      languageText={t('languageSelector')}
      analytics={{ eventPayload: { page_name: analyticsPageName } }}
    />
  )
}
